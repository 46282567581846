<template>

 <div>
   求职
 </div>

</template>


<script>
export default { 


}
</script>